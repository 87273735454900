@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&family=Rubik+Maze&family=Rubik:ital,wght@0,400;0,500;0,800;1,700&display=swap");
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Rubik", sans-serif;
}
.error-message {
  color: red;
  font-size: 14px;
}
.size200 {
  width: 200px;
  height: 200px;
}
.text-primary {
  font-family: "Outfit", sans-serif;
  color: #3699ff;
}

.subHead {
  font-family: "Outfit", sans-serif !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.Outfit {
  font-family: "Outfit", sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}
.Rubik {
  font-family: "Rubik", sans-serif !important;
}
.Rubik_Maze {
  font-family: "Rubik Maze", cursive !;
}

.marginTop {
  margin-top: 12px;
}

.menu_notselected {
  background: white;
}
.custom-backdrop {
  background-color: #6c6c6c !important;
}
.parent {
  width: 15rem;
  height: 11rem;
  background-color: white;
  position: relative;
  border-radius: 10px;
}

.child {
  width: 6rem;
  height: 9rem;
  background-color: white;
  position: absolute;
  top: -20px;
  left: 170px;
  border-radius: 10px;
  box-shadow: 1px;
}
.primary {
  color: #3699ff !important;
}

/* .MuiInputBase-input  {
  height: 3px !important;
} */

.no-shadow {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.home_text {
  /* color: #c37f45; */
}
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px #00000040;
    cursor: not-allowed;
  }
}
.class_group {
  padding: 7px !important;
}
.side_logo {
  border-radius: unset !important;
}
.circular-image {
  width: 30px; /* Adjust the width and height as needed */
  height: 30px;
  border-radius: 50%; /* Makes the image circular */
  overflow: hidden; /* Ensures the image stays within the circular boundary */
}

.circular-image img {
  width: 100%; /* Makes the image fill the circular container */
  height: auto; /* Maintains the image's aspect ratio */
}
.hand{
  cursor: pointer;
}